@font-face {
  font-family: "GTWalsheimPro";
  font-weight: 400;
  src: local("GTWalsheimPro"),
    url("./fonts/GTWalsheimPro/GTWalsheimPro-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "GTWalsheimPro";
  font-weight: 700;
  src: local("GTWalsheimPro"),
    url("./fonts/GTWalsheimPro/GTWalsheimPro-Bold.ttf") format("truetype");
}
